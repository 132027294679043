html, body, #root, canvas, .fullscreen {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overscroll-behavior: contain; /* Disable mobile browser pull-down-to-refresh feature */
}

#buttons-area {
  position: fixed;
  float: right;
  top: 0;
  right: 0;
  margin-top: 5px;
  margin-right: 5px;
  z-index: 1;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100%;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}